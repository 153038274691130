<template>
	<transition name="cookie-consent">
		<client-only>
			<div v-if="!consentGiven" class="cookie-consent">
				<div v-parse-links v-html="content" />
				<span class="cookie-close" @click="giveConsent">
					{{ $t('accept') }}
				</span>
			</div>
		</client-only>
	</transition>
</template>

<script setup>
defineProps({
	content: { type: String, default: '' },
});

const consentGiven = ref(false);

onMounted(() => {
	if (window.localStorage && window.localStorage.getItem('consentGiven')) {
		consentGiven.value = true;
	}
});

const giveConsent = () => {
	window.localStorage && window.localStorage.setItem('consentGiven', 'true');
	consentGiven.value = true;
};
</script>

<style lang="scss" scoped>
.cookie-consent-enter-active {
	transition: opacity 0.4s ease-out;
}

.cookie-consent-leave-active {
	transition: opacity 0.2s ease-out;
}

.cookie-consent-leave-to,
.cookie-consent-enter {
	opacity: 0;
}

.cookie-consent {
	position: fixed;
	width: 100%;
	max-width: 100%;
	inset: auto 0 0;
	z-index: 1000;
	padding: 15px 105px 15px 15px;
	color: #fff;
	box-shadow: rgba(0 0 0 / 30%) 0 1px 3px 0;
	font-size: 14px;
	background: #025a49;

	p,
	:deep(p) {
		margin: 0;
	}

	a,
	:deep(a) {
		text-decoration: underline;
	}

	.cookie-close {
		cursor: pointer;
		font-size: 15px;
		font-weight: 400;
		margin: 0 0 0 20px;
		padding: 8px 16px;
		position: absolute;
		right: 10px;
		top: 14px;
		background: #f86a35;
	}
}
</style>
