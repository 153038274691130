<template>
	<div>
		<footer>
			<div class="row">
				<div class="columns column3">
					<div class="footer-logo" />
				</div>
				<div class="columns column3">
					<h4>{{ $t('contact') }}</h4>
					<p class="contact-links">
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a
							v-if="defaults[locale].website.whatsapp"
							:href="`tel:${$phoneRaw(defaults[locale].website.whatsapp)}`"
						>
							<font-awesome-icon icon="fa-light fa-mobile" size="1x" />
							<span>{{ defaults[locale].website.whatsapp }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<a
						class="external-link"
						href="https://www.google.com/maps?ll=31.628866,-7.979827&z=18&t=m&hl=en&gl=NL&mapclient=embed&cid=18094423618025044761"
						target="_blank"
					>
						<h4>{{ defaults[locale].website.hotelName }}</h4>
						<p>
							<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span
							><br />
							<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span
							><br />
							<span>{{ defaults[locale].website.country }}</span
							><br />
						</p>
					</a>
				</div>
				<div class="columns column3">
					<h4>{{ $t('footerNavigate') }}</h4>
					<p class="footer-nav">
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
					</p>
				</div>
			</div>
			<div class="row">
				<div class="columns column12">
					<hr />
				</div>
			</div>
		</footer>
		<footer class="author">
			<div class="row">
				<div class="columns column12 align-center">
					<p class="align-left">
						<a :href="$t('siteByUrl')" target="_blank" rel="noopener noreferrer"> Hotel Website Design </a>
						by:
						<a :href="$t('siteByUrl')" target="_blank">
							<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="Site by" />
							Porter
						</a>
					</p>
					<p class="align-right">
						<span v-for="(item, idx) in bottomfooter[locale]" :key="item.filename">
							<nuxt-link :to="item.filename">
								{{ item.header }}
							</nuxt-link>
							<span v-if="idx < bottomfooter[locale].length - 1"> | </span>
						</span>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, bottomfooter } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
footer {
	background: $footer-background-color;
	color: #fff;
	padding: 75px 0 10px;
	font-size: 15px;

	hr {
		background: #f4f0e7;
	}

	a {
		color: $footer-link-color;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;

		&.external-link {
			display: block;
			color: $footer-link-color;

			&:hover {
				color: $cta-color;
			}
		}

		svg {
			margin: 0 15px 0 0;
		}

		&:hover {
			color: $cta-color;
		}
	}

	h4 {
		color: #fff;
		letter-spacing: 1px;
	}
}

footer.author {
	padding: 10px 0;
	font-size: 12px;

	.column12 {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	img {
		max-width: 20px;
		margin: 0 1px 0 2px;
	}

	a {
		display: inline-block;
	}
}

.contact-links {
	a {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;

		&:last-child {
			span {
				word-break: break-all;
			}
		}

		svg {
			width: 25px;
			margin: 0 5px 0 0;
			text-align: left;
		}
	}
}

.footer-logo {
	background: url('~/assets/images/logo-riadlivia-white-full.png') no-repeat center center;
	background-size: 100%;
	max-width: 100%;
	width: 200px;
	height: 108px;
	margin: 0 auto 20px;
}

.socials a {
	color: #fff;
	background: $cta-color;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 22px;
	margin: 5px 5px 5px 0;
	text-decoration: none;
}

.socials a:hover {
	color: #fff;
	background: $cta-color-hover;
}

@media (max-width: 920px) {
	footer .columns {
		margin: 20px auto;
	}

	.author .columns {
		margin: 0;
		flex-flow: wrap column-reverse;
	}

	.author p {
		width: 100%;
		margin: 0 0 5px;
		text-align: center;
	}
}

@media (max-width: 680px) {
	footer {
		text-align: center;

		a {
			justify-content: center;
		}

		hr {
			margin: 0;
		}

		.columns {
			margin: 15px 0;
		}
	}

	.contact-links {
		a {
			justify-content: center;
		}
	}
}
</style>
